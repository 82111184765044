import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import Seo from '../components/Seo'
import Releases from '../components/Releases'
import PageTitle from '../components/PageTitle'
import Canonical from '../components/Canonical'

const Music = ({ data }) => {
  const page = data.page

  return (
    <Layout>
      <Seo title={page.title} />
      <Canonical slug={page.slug} />
      <PageTitle title={page.title} />
      <Releases />
      <div dangerouslySetInnerHTML={{ __html: page.content }} />
    </Layout>
  )
}

export default Music

export const pageQuery = graphql`
  query {
    page: wpPage(title: { eq: "music" }) {
      id
      slug
      content
      title
    }
  }
`
