import React from 'react'
// import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useReleases } from '../hooks/use-releases'
import LinkOrHref from './LinkOrHref'

const Releases = ({ ...props }) => {
  const releaseItems = useReleases()
  // const releaseItems = props.data.allWpRelease.edges

  return (
    <ul className="releases list-none grid grid-cols-2 sm:grid-cols-3 gap-4">
      {releaseItems.map((item, i) => {
        const release = item.node
        const { link } = release.releasesACF
        // const { type, tracks, link } = release.releasesACF
        const image = getImage(release.featuredImage.node.gatsbyImage)

        return (
          <li key={i} className="">
            <LinkOrHref url={link}>
              <GatsbyImage image={image} alt={release.title} />
            </LinkOrHref>
          </li>
        )
      })}
    </ul>
  )
}

export default Releases
